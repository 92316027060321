import React from 'react'
import {graphql} from 'gatsby'
import Layout from 'gatsby-theme-psg/src/components/Layout'
import Header from '../components/header/Header'
import Lineup from '../components/Lineup'
import Lead from '../components/header/Lead'

const LineupPage = ({data}) => {
    const { markdownRemark: post } = data
    return (
        <Layout template={'template-lineup'} title={post.frontmatter.title} description={post.frontmatter.description}>
            <Header borderBottom={data.border}>
                <Lead title={post.frontmatter.title} content={post.html} />
            </Header>
            <Lineup numItems={-1} />
        </Layout>
    )
}

export const query = graphql`
    query LineupPage($id: String!) {
        border: file(relativePath: { eq: "lineup/path@3x.png" }) {
            childImageSharp {
                # Specify the image processing specifications right in the query.
                # Makes it trivial to update as your page's design changes.
                fluid(maxWidth: 2048, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
                description
            }
        }
    }
`

export default LineupPage

