import React, {useContext} from 'react'
import Context from 'gatsby-theme-psg/src/components/Events/Context'
import Stage from 'gatsby-theme-psg/src/components/Lineup/Stage'
import {lineupParams} from "gatsby-theme-psg/src/params";
import {Container} from "react-bootstrap";

const Lineup = ({highlighted = 2, numItems = null}) => {
    const {state} = useContext(Context)
    const {event} = state
    const {stages} = event.frontmatter

    return (
        <React.Fragment>
            {(stages && stages.length) ? stages.map((stage, index) => (
                    <div
                        key={index}
                        className={`py-5 ${index % 2 ? 'bg-primary' : 'bg-success-light'}`}
                    >
                        <Container
                            as={"section"}
                            fluid={'lg'}
                        >
                            <heading>
                                <h2
                                    className={'text-success my-5'}
                                    style={{
                                        textShadow: '2px 0 0 #fe7600, -2px 0 0 #0effa5'
                                    }}
                                >
                                    <span>{stage.name}</span>
                                </h2>
                            </heading>
                            <Stage key={index} highlighted={highlighted} numItems={numItems} acts={stage.acts}/>
                        </Container>
                    </div>
                ))
                :
                <h3 className="text-center">{lineupParams.emptyText}</h3>
            }
        </React.Fragment>
    )
}

export default Lineup

